<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title>
        <h5 class="font-weight-bold secondary--text text-h5">Create New Package</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="name"
            label="Package name"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <div class="d-flex align-center gap-5">
            <v-btn @click="validateForm()" :loading="creating" color="primary" small>Save</v-btn>
            <v-btn @click="closeDialog()" :disabled="creating" small text>Cancel</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      name: null,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      creating: state => state.request.status.creatingPackage
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.name = null
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(
          this.$store.dispatch('request/createPackage', this.name)
        )
        .then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>